<template>
  <div class="preview">
    <div
      class="df-row"
      v-for="(pathItem, pathIndex) in pathList"
      :key="pathIndex"
    >
      <div
        class="df-col"
        v-for="(chlidItem, chlidIndex) in pathItem"
        :key="chlidIndex"
        @click="goto(chlidItem.path)"
      >
        <div class="dfWebIcon" :class="chlidItem.class"></div>

        <div class="describe">
          <span class="title">{{ chlidItem.title }}</span>
          <span class="note">{{ chlidItem.note }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rowSpan: 32,
      colSpan: 8,
      pathList: [
        [
          {
            class: "dfWebIcon-jifenshangcheng red-icons",
            title: "积分商城",
            note: "顾客消费获取积分，惠客留客，自助兑换",
            path: "/marketing/inte-exch/inte-exch-record?list_name=inteExch"
          },
          {
            class: "dfWebIcon-fenxiao1 red-icons",
            title: "分销管理",
            note: "分销管理",
            path: "/marketing/distribution/distributor?list_name=distribution"
          },
          {
            class: "dfWebIcon-wenjuantiaoyan1 red-icons",
            title: "问卷管理",
            note: "问卷调研",
            path: "/marketing/questionnaire/list?list_name=questionnaire"
          },
          {
            class: "dfWebIcon-choujiangjieguo red-icons",
            title: "抽奖管理",
            note: "抽奖管理",
            path: "/marketing/raffle/activities?list_name=raffle"
          },
        ],
        [
          {
            class: "dfWebIcon-shouhou1 blue-icons",
            title: "服务跟踪回访",
            note: "短信、微信推送提醒回访顾客、智能客情，提高顾客忠诚度",
            path: "/marketing/tracking?list_name=marketing",
          },
          {
            class: "dfWebIcon-liushiyujing blue-icons",
            title: "流失挽回",
            note: "分析流失风险顾客及时提醒精准挽回",
            path: "/marketing/lost?list_name=marketing",
          },
          {
            class: "dfWebIcon-zhouqiguanli blue-icons",
            title: "消费周期",
            note: "系统智能分析顾客生活习惯，形成周期提醒周期促销",
            path: "/marketing/consume-manage?list_name=marketing",
          },
          {
            class: "dfWebIcon-shengri blue-icons",
            title: "生日营销",
            note: "通过祝福、特殊服务、会员生日福利来吸引和保留顾客",
            path: "/marketing/birthday?list_name=marketing",
          },
          {
            class: "dfWebIcon-wodeyouhuiquan blue-icons",
            title: "优惠券营销",
            note: "设置优惠券精准投放，吸引顾客消费",
            path: "/marketing/coupon?list_name=marketing",
          },
          {
            class: "dfWebIcon-xinrenfanli blue-icons",
            title: "分享折扣顾客裂变",
            note: "顾客分享优惠券给好友享受优惠后，顾客还有返利，刺激共同消费",
            path: "/marketing/rebates?list_name=marketing",
          },
          {
            class: "dfWebIcon-huiyuanquanyi blue-icons",
            title: "权益到期",
            note: "客户权益到期前15天，生成回访任务",
            path: "/marketing/expiration?list_name=marketing",
          },
        ],
        [
          {
            class: "dfWebIcon-shezhi1 yellow-icons",
            title: "小程序引流",
            note: "自定义门店小程序界面和功能，多维度功能结合，现实推广营销，线上健康裂变",
            path: "/setting/applet/feature",
          },
        ],
      ],
    };
  },

  methods: {
    goto(path) {
      // this.isGoto = true
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.color-icons-presets(@backColor: tomato, @fontSize: 26px) {
  .flex-row;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: @fontSize;
  // padding: 8px;
  color: white;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: @backColor;
}

.red-icons {
  // .color-icons-presets(rgb(254, 129, 130));
  .color-icons-presets();
}

.blue-icons {
  .color-icons-presets(#409eff);
}

.yellow-icons {
  .color-icons-presets(rgb(247, 207, 138));
}

.el-menu {
  height: 50px;
  padding: 0 30px;

  .el-menu-item {
    position: relative;
    height: 50px;
    line-height: 50px;
    padding: 0 5px;
    margin-right: 20px;
  }
}

.el-main {
  height: calc(100vh - 60px);
  box-sizing: border-box;
  margin-top: 10px;
  padding: 0 20px;
  background-color: #ffffff;

  .preview {
    width: 100%;
    height: auto;
    // border-radius: 10px;
    // background-color: white;

    .df-row {
      flex-wrap: wrap;
      padding: 20px 0;
      margin: 0;
      border-bottom: 2px solid #f1f1f1;

      .df-col {
        .flex-row;
        width: calc(28% - 60px);
        padding: 16px 30px;
        cursor: pointer;

        .describe {
          .flex-col;
          flex: 1;
          // height: 100%;
          justify-content: space-between;
          margin-left: 16px;

          span {
            width: 100%;
          }

          .title {
            font-weight: bold;
          }

          .note {
            font-size: 14px;
            color: #aaa;
          }
        }
      }
    }
  }
}
</style>
